/* BUTTONS */
.scrbBtnOrange {
  background-color: #f68b2b !important;
  border-color: #f68b2b !important;
  color: black !important;
}

.scrbBtnBlue {
  background-color: #1C76A7 !important;
  border-color: #1C76A7 !important;
}

.scrbBtnBlueRadius {
  background-color: #1C76A7 !important;
  border-color: #1C76A7 !important;
  border-radius: 5px;
  color: #fff;
  padding: 7px;
  text-align: center;
  margin-bottom: 1em;
}

.scrbBtnRed {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.scrbBtnGreen {
  background-color: #259119 !important;
  border-color: #259119 !important;
}

.scrbBtnGreenMargin {
  background-color: #26ad18 !important;
  border-color: #26ad18 !important;
  margin-right: 0.5em;
}

.scrbBtnBlueMargin {
  background-color: #1C76A7 !important;
  border-color: solid #1C76A7 !important;
  margin-right: 0.5em;
}

.filterBtn {
  margin-right: 0.5em;
}

.scrbBtnMrgnR {
  margin-right: 0.5em;
}

.scrbBtnMrgnL {
  margin-left: 0.5em;
}

/* TABLES/LISTS/GRIDS/DETAIL PAGES */
.tableContainer {
  margin-top: 1em;
  border-radius: 0.5em !important;
}

.archiveTable {
  border-radius: 0.5em !important;
}

.searchBarHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 2em;
  border-radius: 0.5em 0.5em 0 0;
  background-color: #ceeaf4;
  border-bottom: solid 2px lightblue;
}

.searchBarTitle {
  text-align: start;
  font-weight: 500;
  margin-top: 0.5em !important;
}

.searchBarBody {
  background-color: #f4f4f4;
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-radius: 0 0 0.5em 0.5em;
}

.tableHeader {
  background-color: #ceeaf4;
  border-radius: 0.5em;
  border-bottom: solid 2px lightblue !important;
}

.tableHeader {
  background-color: #ceeaf4;
  border-radius: 0.5em;
  border-bottom: solid 2px lightblue !important;
}

.th {
  font-weight: 500;
  border-bottom: none !important;
}

.IdHeader {
  padding-left: 20px !important;
  /* padding-left: 1.2500em; */
  text-align: start;
  font-weight: 500;
  border-bottom: solid 2px lightblue !important;
}

.actionsHeader {
  text-align: end;
  padding-right: 20px !important;
  /* padding-right: 1.25em; */
  font-weight: 500;
  border-bottom: solid 2px lightblue !important;
}

.idCell {
  padding-left: 20px !important;
  text-align: start;
}

.indexCell {
  text-align: start;
}

.typeCell {
  text-align: start;
}

.roleCell {
  text-align: start;
}

.actionsCell {
  text-align: end;
  padding-right: 20px !important;
  border-top: none !important;
}

/* POP UP MODALS */
.modalBackdrop {
  background-color: gray !important;
  opacity: 1 !important;
}

/* DETAIL PAGES */
.detailContainer {
  width: 100%;
  padding-left: none;
  padding-right: none;
  background-color: #f4f4f4;
  margin-top: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5em;
  padding-bottom: 1em;
}

.documentSearchContainer {
  width: 100%;
  padding-left: none;
  padding-right: none;
  padding-top: 1em;
  background-color: #f4f4f4;
  margin-top: 0.5em;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5em;
  padding-bottom: 1em;
}

/* .detailContainer {
    background-color: white;
    margin-top: 0.5em;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25em;
    padding-top: 0.5em;
    padding-bottom: 1em;
    box-shadow: 0 0.46875rem 2.1875rem rgb(59 62 102 / 3%), 0 0.9375rem 1.40625rem rgb(59 62 102 / 3%), 0 0.25rem 0.53125rem rgb(59 62 102 / 5%),
      0 0.125rem 0.1875rem rgb(59 62 102 / 3%);
  } */

.detailBtnContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1em;
}

/* META */
.metaForm {
  display: flex;
  justify-content: end;
}

.metaActionsCell {
  display: flex;
  justify-content: end;
  text-align: end;
}

.activeTab {
  background-color: #ceeaf4 !important;
  border-bottom: solid 2px lightblue;
  font-weight: 500;
  cursor: pointer;
  color: black !important;
}

.nonActiveTab {
  cursor: pointer;
  color: black;
  font-weight: 500;
}

.nonActiveTab:hover {
  color: black;
}

.keyValueHeader {
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  margin-bottom: 0.5em;
}

.colonContainer {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 1.5em;
}

/* SCANNER */

.thumbnailViewerContainer {
  border-style: double;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  display: grid;
}

.thumbnailContainer {
  border-style: solid;
  display: grid;
  align-items: center;
  background-color: white;
}

.thumbnailOuterContainer {
  border-style: solid;
  border-color: lightgray;
  border-width: 0;
  padding: 5px;
}
.thumbnailOuterContainer:hover {
  border-width: 3px;
  margin: -3px;
}

.thumbnailOuterContainerSelected {
  border-style: solid;
  border-color: blue;
  background-color: rgb(147, 190, 247);
}

.documentContainer {
  border-style: solid;
  border-color: lightgray;
  border-width: 0;
  padding: 10px 45px 10px 45px;
}

.documentContainerSelected {
  border-style: solid;
  border-color: blue;
  padding: 10px 45px 10px 45px;
  border-width: 3px;
  margin: -3px;
  background-color: rgb(147, 190, 247);
}

.documentContainerError {
  padding: 10px 45px 10px 45px;
  border-width: 3px;
  margin: -3px;
  background-color: rgb(249, 186, 197);
}

.documentContainer:hover {
  border-width: 3px;
  margin: -3px;
}

/* MISC. */
.paginationContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1em;
}

.profileCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profileCard {
  width: 100%;
  max-width: 1140px;
  margin-top: 0.5em;
  box-shadow: 0 0.46875rem 2.1875rem rgb(59 62 102 / 3%), 0 0.9375rem 1.40625rem rgb(59 62 102 / 3%), 0 0.25rem 0.53125rem rgb(59 62 102 / 5%),
    0 0.125rem 0.1875rem rgb(59 62 102 / 3%);
}

.profileImageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 8em;
}

.editIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.profileImage {
  width: 7.75em;
  height: 7.75em;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
}

.contactsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.pencilIcon {
  cursor: pointer;
}

.usersIcon {
  cursor: pointer;
  margin-right: 0.5em;
}

.customLogContainer {
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.roleListItemContainer {
  background-color: #ceeaf4 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid lightblue !important;
  font-weight: 500;
  color: black;
}

.rolePermissionContainer {
  background-color: #ceeaf4 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid lightblue !important;
  font-weight: 500;
  color: black;
}

.accountContactContainer {
  background-color: #ceeaf4 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 2px solid lightblue !important;
  font-weight: 500;
  color: black;
}

/* MAIN */
.mainContainer {
  padding-top: 56px;
  display: flex;
  width: 100%;
  -webkit-box-flex: 1;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background-color: #ffffff;
  height: 100vh;
}

.mainContainerBody {
  height: 100%;
  min-height: 100%;
  width: 100%;
  max-width: 1280px;
  flex-grow: 1;
  position: relative;
  overflow: auto;
}

.mainWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  flex-grow: initial;
  height: 100vh;
}

.scribLogo {
  background-color: #f2f6f5;
  padding: none !important;
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  position: relative;
  align-items: center;
  z-index: 10;
  height: 56px;
  max-height: '56px';
  padding: 'none';
  margin-bottom: 1.75em;
  margin-left: -0.7em;
}
/* SIDE NAV. */
.sideNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-left: 0;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  position: absolute;
  left: 0;
  background: #1C76A7;
  color: #fff;
  z-index: 500003;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  white-space: nowrap;
  position: unset;
}

.navMinimized {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 3em;
  margin-left: 0;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  position: absolute;
  left: 0;
  background: #1C76A7;
  color: #fff;
  padding-top: 1.75em;
  z-index: 100;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  white-space: nowrap;
}

.navMaximized {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.75em;
  height: 100%;
  width: 13em;
  min-width: 13em;
  margin-left: 0;
  transition: width 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  position: absolute;
  left: 0;
  background-color: #1C76A7;
  color: #fff;
  z-index: 100;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  white-space: nowrap;
}

.navBarBottom {
  display: flex;
  flex-direction: column;
  padding-left: 0.7em;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-top: 1px solid white;
}

.menuDivider {
  margin-top: 1.75em;
  margin-bottom: 0.5em;
  margin-right: 0.75em;
  border-top: 1px white dotted;
  background-image: linear-gradient(to bottom, white 40%, rgba(255, 255, 255, 0) 20%);
  background-position: left;
  background-size: 1px 3px;
  background-repeat: repeat-y;
}

.hamburgerIcon {
  cursor: pointer;
  color: white;
  height: 1.5em;
  min-height: 1.5em;
  min-width: 1.5em;
  padding: 0px !important;
}

.dropdownToggle {
  display: flex;
  max-width: 100%;
}

.menuBtn {
  color: white;
  height: 1.5em;
  padding: 0px !important;
}

.navLink {
  position: relative;
  padding-left: 0px !important;
  padding-right: 0px !important;
  color: white;
  font-weight: bold;
  display: flex !important;
  align-items: center;
  cursor: pointer;
}

.navListContainer {
  padding-left: 0.7em;
  width: 100%;
}

.navMenuItem {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navMenuItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
}

.selectedMenuItem {
  position: static;
  margin-left: 0.6em;
  height: 1em;
  width: 0.3em;
  border-radius: 0 5px 5px 0;
  background-color: #f68b2b;
  right: 0;
}

.selectedMenuItemOpen {
  display: block;
  position: fixed;
  left: 0px;
  margin-left: 12.9em;
  height: 1em;
  width: 0.3em;
  border-radius: 0 5px 5px 0;
  background-color: #f68b2b;
  right: 0;
}

@media screen and (max-width: 769px) {
  .selectedMenuItemOpen {
    background-color: #f68b2b;
  }
}

.menuItemTxt {
  margin-left: 7px;
  overflow: hidden;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.menuItemTxtHidden {
  display: none;
}

.accountProfileImg {
  width: 1.65em;
  height: 1.65em;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.link {
  color: black;
  text-decoration: none !important;
  padding-left: 0px;
}

.menuNavLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.scrollable {
  max-height: 500px;
  overflow-y: auto;
}

.checkMark {
  margin-left: 0.7em;
  padding-bottom: 0.2em;
}

.noResultsAlert {
  margin-top: 15px;
  text-align: center;
}
